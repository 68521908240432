body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  border-radius: 0 !important;
}
.App {
  /* text-align: center; */
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}


@media all and (min-width: 480px) {
  .Login {
    padding: 60px 0;
    margin: 0 auto;
    max-width: 320px;
  }

  
}

.login-panel-title {
  background-color: white;
  color: #777;
  font-size: 34px;
  
}

.login-form-group {
  margin-top: 50px
}
.loader {
  margin: 20px auto;
  width: 100%;
  left: 0;
  text-align: center;
  position: absolute;
}
.my-modal-dialog {
  padding-top: 10%;
  background-color: rgba(0,0,0,.5);
}
.red {
  color: red;
}
.match {
  border-radius: 5px;
  padding: 10px;
}
.match:nth-child(even){background-color: #f2f2f2;}
.match:nth-child(odd){background-color: #fff;}
.match:hover {background-color: #ddd;}

.actions-dropdown {
  padding-left: 0;
  margin: -7px 0;
}

.matches-panel {
  border: none;
}
.panel-title-align {
  text-align: center;
}
.matches-panel-body {
  padding: 0 15px;
  text-align: center;
}

.no-results-found {
  background-color: white;
  color: #777;
  font-size: 34px;
  line-height: 2;
}

.filter-panel {
  margin-top: -15px;
}

.filter-button {
  margin-left: 10px;
}

.filter-label {
  background-color: #f5f5f5;
  color: #777;
  font-size: 14px;
}

.filter-search-icon {
  margin-top: 10px;
  margin-left: -50px;
}
.save-button {
  margin-right: 50px
}
.red {
  color: red;
}
.match {
  border-radius: 5px;
  padding: 10px;
}
.match:nth-child(even){background-color: #f2f2f2;}
.match:nth-child(odd){background-color: #fff;}
.match:hover {background-color: #ddd;}

.actions-dropdown {
  padding-left: 0;
  margin: -7px 0;
}

.matches-panel {
  border: none;
}
.panel-title-align {
  text-align: center;
}
.matches-panel-body {
  padding: 0 15px;
  text-align: center;
}

.no-results-found {
  background-color: white;
  color: #777;
  font-size: 34px;
  line-height: 2;
}

.filter-panel {
  margin-top: -15px;
}

.filter-button {
  margin-left: 10px;
}

.filter-label {
  background-color: #f5f5f5;
  color: #777;
  font-size: 14px;
}

.filter-search-icon {
  margin-top: 10px;
  margin-left: -50px;
}
.notfound-block {
  text-align: center;
}
.no-results-found {
  background-color: white;
  color: #777;
  font-size: 34px;
  margin-top: 10%;
}
