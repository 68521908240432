@media all and (min-width: 480px) {
  .Login {
    padding: 60px 0;
    margin: 0 auto;
    max-width: 320px;
  }

  
}

.login-panel-title {
  background-color: white;
  color: #777;
  font-size: 34px;
  
}

.login-form-group {
  margin-top: 50px
}