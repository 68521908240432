.red {
  color: red;
}
.match {
  border-radius: 5px;
  padding: 10px;
}
.match:nth-child(even){background-color: #f2f2f2;}
.match:nth-child(odd){background-color: #fff;}
.match:hover {background-color: #ddd;}

.actions-dropdown {
  padding-left: 0;
  margin: -7px 0;
}

.matches-panel {
  border: none;
}
.panel-title-align {
  text-align: center;
}
.matches-panel-body {
  padding: 0 15px;
  text-align: center;
}

.no-results-found {
  background-color: white;
  color: #777;
  font-size: 34px;
  line-height: 2;
}

.filter-panel {
  margin-top: -15px;
}

.filter-button {
  margin-left: 10px;
}

.filter-label {
  background-color: #f5f5f5;
  color: #777;
  font-size: 14px;
}

.filter-search-icon {
  margin-top: 10px;
  margin-left: -50px;
}